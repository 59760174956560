import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/layout";

const PropertyHistoryBack = loadable(() => import("../components/PropertyHistoryBack/PropertyHistoryBack"));
const PropertyBannerModule = loadable(() => import("../components/PropertyBannerModule/PropertyBannerModule"));
const PropertyDescription = loadable(() => import("../components/PropertyDescription/PropertyDescription"));
const PropertyDetailsGallery = loadable(() => import("../components/PropertyDetailsGallery/PropertyDetailsGallery"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));
const FooterContactMobile = loadable(() => import("../components/FooterContactMobile/FooterContactMobile"));

const PropertyDetail = (props) => {
    return (
        <Layout
            layout={""}
            footertag={"footer-contact"}
        >
            <div className="layout-padding-top"></div>

            <PropertyHistoryBack />

            <PropertyBannerModule />

            <PropertyDescription />

            <PropertyDetailsGallery />

            <PropertyDetailsMap
                lat={54.2317049}
                lng={-13.4261146}
            />

            <FeaturedProperties
                tag="property-details"
            />

            <PatternBanner 
                tag = "brown"
                title = "start your journey"
                desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community."
            />

            <div className="d-md-none">
                <FooterContactMobile />
            </div>
        </Layout>
    )
}

export default PropertyDetail